import React, { Suspense } from 'react'
import { Banner, Introduction, Help, Faq, FeaturesBenefits, Pricing } from '../components'
import { facialJohn, retailBenefits, facialDataInsights, facialFaceDetection, facialSecureData, retailsIntegration, retailsIntroduction, retailshelp } from '../../../helper/utils'
import Button from '../../../common/Button'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../common/Loader'
import CashStudies from '../components/CashStudies'



const featuresBenefits = [
  {
    img: facialFaceDetection,
    head: 'Advanced Identification',
    para: 'Fast and accurate identification for security and customer service.',
  },
  {
    img: facialSecureData,
    head: 'Enhanced Security Features',
    para: 'Real-time monitoring and alert systems for improved safety.',
  },
  {
    img: facialDataInsights,
    head: 'Data Insights',
    para: 'Valuable analytics for customer behavior and demographic analysis.',
  },
  {
    img: retailBenefits,
    head: 'Benefits',
    para: 'Increased safety, personalized experiences, and operational efficiencies across sectors.',
  },
]

const pricingData = [
  {
    head: 'Starter',
    para: 'Ideal for small retail stores.',
    price: '$99.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Advanced',
    para: 'Designed for medium-sized businesses with more complex needs.',
    price: '$149.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Premium',
    para: 'Tailored for large retail chains and malls with custom feature requirements.',
    price: '$199.99',
    buttonName: 'Choose Plan'
  }
]

const cashStudies = [
  {
    review: "VISNX has revolutionized our approach to customer interaction and security. Its accuracy and reliability are unmatched.",
    image: facialJohn,
    name: 'George Jones',
    position: 'Security Manager, AuraIdentify Corp'
  }
]

const faqList = [
  {
    que: 'How does VISNX ensure the privacy and security of facial recognition data?',
    ans: 'We adhere to strict privacy policies and use state-of-the-art encryption to protect data.',
  },
  {
    que: 'Is VISNX Facial Recognition customizable to specific industry needs?',
    ans: 'Yes, our solution can be tailored to meet the unique requirements of different industries.',
  }
]

const FacialRecognition = () => {
  const navigate = useNavigate();
  return (
    <div className='bg-dark'>
      <Banner
        title='Industry Facial Recognition | Facial Recognition in India | VISNX'
        description="Elevate identification standards with VISNX Facial Recognition. Secure, accurate & versatile solutions redefine experiences in retail, finance, safety & hospitality."
        heading='Transforming Identification with VISNX Facial Recognition'
        subHeading='Innovative, Accurate, and Secure Facial Recognition for Every Industry'
        bannerImage='solution_facial_banner'
      />
      <Introduction
        heading='Introduction to VISNX Facial Recognition'
        para='VISNX Facial Recognition represents the pinnacle of AI-driven biometric technology, offering a seamless and secure way to identify and verify individuals. Ideal for a multitude of applications, from enhancing security protocols to providing personalized customer experiences, VISNX is your solution for reliable and ethical facial recognition.'
        image={retailsIntroduction}
      />
      <Suspense fallback={<Loader />}>
        <Help
          heading='How We Help Different Industries'
          para="VISNX provides versatile facial recognition solutions across diverse sectors. Enhance retail customer engagement with personalized experiences, bolster security in banking and finance, innovate public safety measures, or streamline processes in hospitality. Each industry benefits from tailored, cutting-edge facial recognition technology."
          image={retailshelp}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <FeaturesBenefits featuresBenefits={featuresBenefits} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <CashStudies cashStudies={cashStudies} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Introduction
          heading='Integration and Compatibility'
          para="VISNX Facial Recognition seamlessly integrates with your existing systems and infrastructure. Compatible with a range of camera systems and operational software, it is designed to be flexible and adaptable to the specific needs of your industry."
          image={retailsIntegration}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Pricing pricingData={pricingData} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Faq faqList={faqList} />
      </Suspense>
      <div className='relative'>
        <div className='contact_banner_home'></div>
        <div className='absolute bottom-0 left-0 w-full h-[200px] z-40 md:p-10 sm:p-7 p-4'>
          <div className='md:text-2xl sm:text-xl text-sm font-medium flex flex-col md:gap-3 gap-3 justify-center items-center h-full w-full text-center max-w-[1000px] m-auto'>
            <div className='text-center'>
              <div>Take the next step in security and customer engagement with VISNX Facial Recognition. Contact us today to learn more or to schedule a demonstration. Elevate your business with cutting-edge facial recognition technology.</div>
            </div>
            <div className=''><Button buttonName='Book a Demo' onclick={() => navigate('/contact-us')} /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FacialRecognition