import React, { Suspense } from 'react'
import { Banner, Introduction, Help, Faq, FeaturesBenefits, Pricing } from '../components'
import { oilJohn, oilCompliance, oilMaintenance, oilMonitoring, retailsIntegration, retailsIntroduction, retailshelp, retailBenefits } from '../../../helper/utils'
import Button from '../../../common/Button'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../common/Loader'
import CashStudies from '../components/CashStudies'

const faqList = [
  {
    que: 'How does VISNX ensure data security?',
    ans: 'We employ advanced encryption and security protocols to protect your data.',
  },
  {
    que: 'Can VISNX integrate with my existing cameras?',
    ans: 'Yes, VISNX is designed for compatibility with a wide range of camera systems.',
  }
]

const featuresBenefits = [
  {
    img: oilMonitoring,
    head: 'Real-Time Monitoring',
    para: 'Continuous surveillance of facilities for immediate incident response.',
  },
  {
    img: oilCompliance,
    head: 'Compliance Assurance',
    para: 'Automated checks for industry compliance and safety standards.',
  },
  {
    img: oilMaintenance,
    head: 'Predictive Maintenance',
    para: 'AI-driven analytics to predict and prevent equipment failures.',
  },
  {
    img: retailBenefits,
    head: 'Benefits',
    para: 'These features lead to reduced operational costs, improved safety records, and enhanced overall efficiency.',
  },
]

const pricingData = [
  {
    head: 'Starter',
    para: 'Ideal for small retail stores.',
    price: '$99.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Advanced',
    para: 'Designed for medium-sized businesses with more complex needs.',
    price: '$149.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Premium',
    para: 'Tailored for large retail chains and malls with custom feature requirements.',
    price: '$199.99',
    buttonName: 'Choose Plan'
  }
]

const cashStudies = [
  {
    review: 'VISNX has been a game-changer in our operational strategy, significantly enhancing our safety protocols.',
    image: oilJohn,
    name: 'Daniel Stone',
    position: 'Safety Manager, EnerGeo Oil Corp'
  }
]

const OilGas = () => {
  const navigate = useNavigate();
  return (
    <div className='bg-dark'>
      <Banner
        title='Advanced Video Analytics for Oil and Gas Industry | VISNX'
        description="Revolutionize Oil & Gas with VISNX's Advanced Video Analytics. Monitor critical infrastructure, ensure safety, and optimize compliance for proactive decision-making"
        heading='Revolutionizing Oil & Gas Operations with Advanced Video Analytics'
        subHeading='Enhance Safety, Efficiency, and Compliance with VISNX'
        bannerImage='solution_oil_banner'
      />
      <Introduction
        heading='Introduction to Video Analytics'
        para='Video Analytics is transforming how industries operate, and the Oil & Gas sector is at the forefront of this revolution. VISNX leverages cutting-edge AI to turn video data into actionable insights, optimizing operations, enhancing safety, and ensuring regulatory compliance. Experience the next level of operational intelligence with VISNX.'
        image={retailsIntroduction}
      />
      <Suspense fallback={<Loader />}>
        <Help
          heading='How We Help the Oil and Gas Industry'
          para='In the challenging environment of the Oil & Gas industry, VISNX addresses key operational concerns. From monitoring critical infrastructure to ensuring safety protocol compliance, our solution provides real-time insights for proactive decision-making. Reduce operational downtime, enhance safety measures, and streamline processes with our tailored video analytics solutions.'
          image={retailshelp}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <FeaturesBenefits featuresBenefits={featuresBenefits} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <CashStudies cashStudies={cashStudies} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Introduction
          heading='Integration and Compatibility'
          para="VISNX seamlessly integrates with your existing infrastructure, ensuring a smooth transition to advanced analytics. Compatible with a range of camera systems and operational software, it's designed to be flexible and scalable, meeting the diverse needs of the Oil & Gas industry."
          image={retailsIntegration}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Pricing pricingData={pricingData} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Faq faqList={faqList} />
      </Suspense>
      <div className='relative'>
        <div className='contact_banner_home'></div>
        <div className='absolute bottom-0 left-0 w-full h-[200px] z-40 md:p-10 sm:p-7 p-4'>
          <div className='md:text-2xl sm:text-xl text-sm font-medium flex flex-col md:gap-3 gap-3 justify-center items-center h-full w-full text-center max-w-[1000px] m-auto'>
            <div className='text-center'>
              <div>Ready to transform your Oil & Gas operations with VISNX? Contact our team today for a detailed demo or to discuss your specific needs. Elevate your operational intelligence now!</div>
            </div>
            <div className=''><Button buttonName='Book a Demo' onclick={() => navigate('/contact-us')} /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OilGas