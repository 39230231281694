import React, { Suspense } from 'react'
import Button from '../../common/Button'
import { useNavigate } from 'react-router-dom'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Loader from '../../common/Loader'
import Banner from './Banner';
const Client = React.lazy(() => import('./Client'));
const Industries = React.lazy(() => import('./Industries'));
const Solution = React.lazy(() => import('./Solution'));
const Testimonial = React.lazy(() => import('./Testimonial'));
const WithVisnx = React.lazy(() => import('./WithVisnx'));

const Home = () => {
  const navigate = useNavigate();
  // document.title = 'AI-Driven Video Analytics Software Solutions - VISNX';
  // const ogTitle = document.querySelector('meta[name="title"]');
  // if (ogTitle) {
  //   ogTitle.setAttribute('content', 'AI-Driven Video Analytics Software Solutions - VISNX');
  // }
  // const ogTitleTag = document.querySelector('meta[property="og:title"]');
  // if (ogTitleTag) {
  //   ogTitleTag.setAttribute('content', 'AI-Driven Video Analytics Software Solutions - VISNX');
  // }
  // const twitterTitleTag = document.querySelector('meta[property="twitter:title"]');
  // if (twitterTitleTag) {
  //   twitterTitleTag.setAttribute('content', 'AI-Driven Video Analytics Software Solutions - VISNX');
  // }

  // const description = document.querySelector('meta[name="description"]');
  // if (description) {
  //   description.setAttribute('content', 'Empower your business with VISNX AI-Driven Video Analytics Software. Get security for retail, manufacturing, & construction industries with our Smart City Security Solutions now.');
  // }
  // const ogDescriptionTag = document.querySelector('meta[property="og:description"]');
  // if (ogDescriptionTag) {
  //   ogDescriptionTag.setAttribute('content', 'Empower your business with VISNX AI-Driven Video Analytics Software. Get security for retail, manufacturing, & construction industries with our Smart City Security Solutions now.');
  // }
  // const twitterDescriptionTag = document.querySelector('meta[property="twitter:description"]');
  // if (twitterDescriptionTag) {
  //   twitterDescriptionTag.setAttribute('content', 'Empower your business with VISNX AI-Driven Video Analytics Software. Get security for retail, manufacturing, & construction industries with our Smart City Security Solutions now.');
  // }

  // const ogurl = document.querySelector('meta[property="og:url"]');
  // if (ogurl) {
  //   ogurl.setAttribute('content', `https://visnx.vercel.app${location.pathname}`);
  // }
  // const twitterurl = document.querySelector('meta[property="twitter:url"]');
  // if (twitterurl) {
  //   twitterurl.setAttribute('content', `https://visnx.vercel.app${location.pathname}`);
  // }

  // const ogImage = document.querySelector('meta[property="og:image"]');
  // if (ogImage) {
  //   ogImage.setAttribute('content', `https://visnx.vercel.app${BannerImage}`);
  // }
  // const twitterImage = document.querySelector('meta[property="twitter:image"]');
  // if (twitterImage) {
  //   twitterImage.setAttribute('content', `https://visnx.vercel.app${BannerImage}`);
  // }

  const title = 'Transforming Businesses through Video Analytics in India | VISNX';
  const description = 'Empower your business with VISNX AI-Driven Video Analytics Software. Get security for retail, manufacturing, & construction industries with our Smart City Security Solutions now.';
  return (
    <HelmetProvider>
      <div className='bg-dark'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <Banner />
        <Suspense fallback={<Loader />}>
          <Client />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Solution />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Testimonial />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Industries />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <WithVisnx />
        </Suspense>
        <div className='relative md:pt-16 pt-10'>
          <div className='contact_banner_home'></div>
          <div className='absolute bottom-0 left-0 w-full h-[200px] z-40'>
            <div className='md:text-4xl sm:text-2xl text-2xl font-medium flex flex-col md:gap-3 gap-3 justify-center items-center h-full w-full text-center'>
              <div className='text-center'>
                <div>Book a Free Demo Now</div>
              </div>
              <div className=''><Button buttonName='Contact us' onclick={() => navigate('/contact-us')} /></div>
            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  )
}

export default Home