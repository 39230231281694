import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { blogData } from '../../helper';
import { Helmet, HelmetProvider } from 'react-helmet-async'


const BlogDetails = () => {
  const navigate = useNavigate()
  const [blog, setBlog] = useState({});
  const { id } = useParams()
  useEffect(() => {
    if (id) {
      setBlog(blogData.find(ele => ele.route === id))
    }
  }, [id])

  const title = blog?.heading || 'AI-Driven Video Analytics Software Solutions - VISNX';
  const description = blog?.para || 'Empower your business with VISNX AI-Driven Video Analytics Software. Get security for retail, manufacturing, & construction industries with our Smart City Security Solutions now.';

  const blogArray = blogData.slice().reverse();

  return (
    <HelmetProvider>
      <div className='bg-dark blog_details'>
        <Helmet>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={description} />

          <meta property="og:type" content="website" />
          <meta property="og:url" content={`https://visnx.com/blog/${blog.route}`} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={`https://visnx.com/${blog.image}`} />

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content={`https://visnx.com/blog/${blog.route}`} />
          <meta property="twitter:title" content={title} />
          <meta property="twitter:description" content={description} />
          <meta property="twitter:image" content={`https://visnx.com/${blog.image}`} />
        </Helmet>
        <div className='2xl:max-w-[1500px] max-w-[95%] m-auto md:py-14 py-10 '>
          <div className='grid lg:grid-cols-4 sm:justify-center gap-5 mt-5'>
            <div className='lg:col-span-3'>
              <img className='w-full h-auto object-cover' src={blog?.image} alt='blog_banner' rel='payload' />
              <div className='' dangerouslySetInnerHTML={{ __html: blog?.data }} ></div>
              <div className='grid gap-3 text-justify font-light'>
              </div>
            </div>
            <div className='col-span-1'>
              <div className='grid grid-cols-1 gap-4'>
                {
                  blogArray.map((data, i) => {
                    return (
                      i <= '4' &&
                      <div key={i} onClick={() => {
                        navigate(`/blog/${data.route}`)
                      }}
                        className='flex gap-3 items-center cursor-pointer p-3 bg-bgSecondary transition-all duration-600 ease-out'>
                        <div className='w-[100px]'>
                          <img className='w-[100px] h-[100px] object-cover' src={data.image} rel='payload' alt='blog_banner' />
                        </div>
                        <div className='w-[60%] text-sm line-clamp-2 overflow-hidden'>{data.heading}</div>
                      </div>
                    )
                  })
                }
              </div>

            </div>
          </div>
        </div>
      </div>
    </HelmetProvider>
  )
}

export default BlogDetails