import React from 'react'

const Introduction = ({ heading, para, image }) => {
    return (
        <div className='2xl:max-w-[1500px] max-w-[95%] m-auto md:py-16 py-5'>
            <div className='bg-bgSecondary md:p-10 sm:p-7 p-4'>
                <div className='flex lg:flex-row flex-col-reverse md:gap-10 gap-5 items-center justify-center'>
                    <div className='lg:w-[50%] w-full'>
                        <div className='md:text-3xl text-xl font-bold text-start text-primary'>{heading}</div>
                        <div className='md:text-lg text-sm font-light text-start md:pt-2 pt-1 '>{para}</div>
                    </div>
                    <div className='lg:w-[50%] w-full'>
                        <img rel='payload' src={image} alt='introduction_image' className='md:h-[300px] h-[200px] w-full object-cover' height={250} width={600} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Introduction