import './App.css';
import Footer from './common/Footer';
import Header from './common/Header';
import Home from './components/home/Home';
import Contact from './components/contact/Contact';
import { Route, Routes, useLocation } from "react-router-dom";
import ScrollToTop from './helper/ScrollToTop';
import Blog from './components/blog/Blog';
import BlogDetails from './components/blog/BlogDetails';
import BecomePartner from './components/become-partner/BecomePartner';
import PageNotFound from './components/PageNotFound/PageNotFound';
import { Retails, Construction, FacialRecognition, Manufacturing, OilGas, SmartCities } from './components/solution/pages';

function App() {
  const location = useLocation();
  const slug = location.pathname.split("/")[1];

  return (
    <ScrollToTop>
      <div className="">
        {slug !== "error404" && <Header />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/partner" element={<BecomePartner />} />
          <Route path="/solutions/retail-video-analytics-system" element={<Retails />} />
          <Route path="/solutions/oil-gas-video-analytics" element={<OilGas />} />
          <Route path="/solutions/video-analytics-construction" element={<Construction />} />
          <Route path="/solutions/smart-facial-recognition-solution" element={<FacialRecognition />} />
          <Route path="/solutions/smart-city-video-analytics" element={<SmartCities />} />
          <Route path="/solutions/smart-manufacturing-analytics" element={<Manufacturing />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {slug !== "error404" && <Footer />}
      </div>
    </ScrollToTop>
  );
}

export default App;
