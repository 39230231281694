import React from "react";
import { Link } from "react-router-dom";
import {
  FaInstagram,
  BsFacebook,
  BiLogoLinkedin,
  AiFillYoutube,
  FaXTwitter,
} from "../helper/icon";
import { Logo, g2icon } from "../helper/utils";
const Footer = () => {
  return (
    <>
      <div className="bg-bgSecondary">
        <div className="2xl:max-w-[1500px] max-w-[95%] m-auto py-10">
          <div className="grid md:grid-cols-3 md:gap-10 gap-6">
            <div>
              <div className="mb-2">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="Main Logo"
                    width="130"
                    height="25"
                    rel="payload"
                    loading="lazy"
                    fetchpriority="low"
                    srcSet={`${Logo} 100w, ${Logo} 130w`}
                    sizes="(max-width: 600px) 100px, 130px"
                  />
                </Link>
              </div>
              <div>Unveiling the Power of Video Analytics</div>
              <div className="flex gap-3 pt-2 items-center">
                <Link
                  to="https://www.facebook.com/profile.php?id=61560148652658"
                  target="_blank"
                >
                  <BsFacebook />
                </Link>
                <Link to="https://www.instagram.com/visnx_ai/" target="_blank">
                  <FaInstagram />
                </Link>
                <Link to="https://x.com/visnxai/" target="_blank">
                  <FaXTwitter />
                </Link>
                <Link
                  to="https://www.linkedin.com/company/visnx-video-analytics-platform/"
                  target="_blank"
                >
                  <BiLogoLinkedin />
                </Link>
                <Link
                  to="https://www.youtube.com/channel/UCy6dvTXrtMt7np16ZihtbTw/"
                  target="_blank"
                >
                  <AiFillYoutube size={25} />
                </Link>
                <Link
                  to="https://www.g2.com/products/visnx/reviews/"
                  target="_blank"
                >
                  <img src={g2icon} alt="g2 icon" className="h-4 w-4" />
                </Link>
              </div>
            </div>
            <div>
              <div className="font-normal text-white">
                Transforming visual data into powerful insights, we are your
                reliable partner in cutting-edge Video Analytics Software
                Solutions.
              </div>
            </div>
            <div>
              <div>
                <div className="font-bold mb-2">Address:</div>
                <div className="text-sm font-normal text-white mb-[4px]">
                  Fortune Hub, Science City Road, <br /> Ahmedabad, India
                </div>
                <div className="text-sm font-normal text-white mb-[2px]">
                  <Link to="tel: +91 95106 90757">+91 9510690757</Link>
                </div>
                <div className="text-sm font-normal text-white">
                  <Link to="mailto: info@visnx.com"> info@visnx.com</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center bg-dark p-4 text-sm">
        @2023 VISNX. All rights reserved.
      </div>
    </>
  );
};

export default Footer;
