import React from 'react'

const CashStudies = ({cashStudies}) => {
    return (
        <div className='2xl:max-w-[1500px] max-w-[95%] m-auto md:pt-16 pt-5'>
            {/* <div className='md:text-3xl text-xl font-semibold mb-5 text-primary text-center'>Case Studies</div> */}
            <div>
                {
                    cashStudies.map((data,i) => {
                        return (
                            <div key={i}>
                                <div className='!flex md:flex-row flex-col bg-bgSecondary sm:gap-10 gap-5 md:p-10 sm:px-7 py-0 px-4'>
                                    <div className='md:w-[60%] w-full flex flex-col justify-between h-[unset] md:gap-10 gap-5 sm:py-7 py-4'>
                                        <div className='xl:text-2xl lg:text-xl text-sm'>{data.review}</div>
                                        <div>
                                            <div className='sm:text-base text-xs text-primary'>{data.name}</div>
                                            <div className='sm:text-base text-xs text-[#c1c1c1] font-light'>{data.position}</div>
                                        </div>
                                    </div>
                                    <div className='md:w-[40%] md:block hidden relative'>
                                        <img src={data.image} rel='payload' alt='success_stories' className='w-full h-[300px] object-contain z-50 relative'/>
                                    </div>
                                </div>
                            </div>

                        )
                    })
                }
            </div>
        </div>
    )
}

export default CashStudies