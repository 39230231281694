import React, { Suspense } from 'react'
import { Banner, Introduction, Help, Faq, FeaturesBenefits, Pricing } from '../components'
import { retailBenefits, smartcityPublicSafety, smartcityTrafficFlow, smartcityUrbanPlanning, retailsIntegration, retailsIntroduction, retailshelp, smartcityJohn } from '../../../helper/utils'
import Button from '../../../common/Button'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../common/Loader'
import CashStudies from '../components/CashStudies'



const featuresBenefits = [
  {
    img: smartcityTrafficFlow,
    head: 'Traffic Flow Analysis',
    para: 'Manage congestion and improve traffic flow.',
  },
  {
    img: smartcityPublicSafety,
    head: 'Public Safety Monitoring',
    para: 'Enhance security with real-time surveillance analysis.',
  },
  {
    img: smartcityUrbanPlanning,
    head: 'Urban Planning Insights',
    para: ' Data-driven insights for effective city planning.',
  },
  {
    img: retailBenefits,
    head: 'Benefits',
    para: 'Improved urban mobility, enhanced public safety, and informed urban development decisions.',
  },
]

const pricingData = [
  {
    head: 'Starter',
    para: 'Ideal for small retail stores.',
    price: '$99.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Advanced',
    para: 'Designed for medium-sized businesses with more complex needs.',
    price: '$149.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Premium',
    para: 'Tailored for large retail chains and malls with custom feature requirements.',
    price: '$199.99',
    buttonName: 'Choose Plan'
  }
]

const cashStudies = [
  {
    review: "VISNX's video analytics has been a game-changer for our city's safety and planning strategies.",
    image: smartcityJohn,
    name: 'Ethan Brown',
    position: 'City Planner, UrbanPulse Municipality.'
  }
]

const faqList = [
  {
    que: 'How does VISNX handle data privacy in public spaces?',
    ans: 'We prioritize privacy and comply with all relevant data protection regulations.',
  },
  {
    que: "Can VISNX's system integrate with other smart city technologies?",
    ans: 'Yes, VISNX is designed for interoperability with various smart city platforms and technologies.',
  }
]

const SmartCities = () => {
  const navigate = useNavigate();
  return (
    <div className='bg-dark'>
      <Banner
        title='Smart Cities Transformation with Video Analytics in India | VISNX'
        description="Experience the future of Smart Cities with VISNX Video Analytics. Empower urban safety, efficiency & intelligence for better resource management & resident well-being."
        heading='Empowering Smart Cities with VISNX Video Analytics'
        subHeading='Innovative Solutions for Urban Safety, Efficiency, and Intelligence'
        bannerImage='solution_smartcity_banner'
      />
      <Introduction
        heading='Introduction to Video Analytics'
        para="Video Analytics is revolutionizing urban management. VISNX leverages advanced AI to transform video data into actionable insights, paving the way for smarter, safer, and more efficient cities. Embrace the future of urban living with VISNX's cutting-edge video analytics."
        image={retailsIntroduction}
      />
      <Suspense fallback={<Loader />}>
        <Help
          heading='How We Help Smart Cities'
          para="VISNX is at the forefront of driving Smart City transformations. Our solution aids in traffic management, public safety, and urban planning. By harnessing the power of video analytics, cities can enhance situational awareness, optimize resource allocation, and improve the quality of life for their residents."
          image={retailshelp}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <FeaturesBenefits featuresBenefits={featuresBenefits} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <CashStudies cashStudies={cashStudies} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Introduction
          heading='Integration and Compatibility'
          para="VISNX integrates seamlessly with existing urban infrastructure and surveillance systems, ensuring a smooth transition to advanced analytics. Compatible with various camera types and urban data systems, it's designed for adaptability and scalability."
          image={retailsIntegration}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Pricing pricingData={pricingData} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Faq faqList={faqList} />
      </Suspense>
      <div className='relative'>
        <div className='contact_banner_home'></div>
        <div className='absolute bottom-0 left-0 w-full h-[200px] z-40 md:p-10 sm:p-7 p-4'>
          <div className='md:text-2xl sm:text-xl text-sm font-medium flex flex-col md:gap-3 gap-3 justify-center items-center h-full w-full text-center max-w-[1000px] m-auto'>
            <div className='text-center'>
              <div>Join the smart city revolution with VISNX. Contact us today to learn how our video analytics can transform your city. Let’s build smarter, safer, and more sustainable urban environments together.</div>
            </div>
            <div className=''><Button buttonName='Book a Demo' onclick={() => navigate('/contact-us')} /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmartCities