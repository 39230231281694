"use client"
import React, { useEffect, useState } from 'react'
import { IoMenu, IoClose } from '../helper/icon'
import { Link } from "react-router-dom";
import { Logo } from '../helper/utils';
import { RiArrowDropDownLine } from 'react-icons/ri';

const Header = () => {
  const [click, setClick] = useState(false);
  const [isResourceMenuOpen, setIsResourceMenuOpen] = useState(false);
  const [isSolutionMenuOpen, setIsSolutionMenuOpen] = useState(false);
  const handleClick = () => setClick(!click);
  // const closeMobileMenu = () => setClick(false);

  const toggleResourceMenu = () => {
    setIsResourceMenuOpen(!isResourceMenuOpen);
    setIsSolutionMenuOpen(false);
  };
  const toggleSolutionMenu = () => {
    setIsSolutionMenuOpen(!isSolutionMenuOpen);
    setIsResourceMenuOpen(false);
  };
  const handleBackgroundClick = () => {
    setIsResourceMenuOpen(false);
    setIsSolutionMenuOpen(false);
  };
  const handleItemClick = () => {
    setClick(false);
    setIsResourceMenuOpen(false);
    setIsSolutionMenuOpen(false);
  };


  useEffect(() => {
    if (click === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [click]);

  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    // Define a function to handle scroll events
    const handleScroll = () => {
      if (window.scrollY > 500) {
        // Change the condition as per your scroll threshold
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='fixed top-0 w-full z-[9999]'>
      {
        isResourceMenuOpen || isSolutionMenuOpen ?
          <div className='absolute w-screen h-screen background_click' onMouseUp={handleBackgroundClick}></div> : ''
      }
      <div className={`${scrolling ? 'bg-dark' : 'header_background_gradient'}`}>
        <div className='z-[999] flex items-center justify-between m-auto gap-5 2xl:max-w-[1500px] max-w-[95%] md:p-4 py-3'>
          <Link to="/" onClick={() => setClick(false)}>
            <img
              src={Logo}
              alt="Main Logo"
              width='130'
              height='25'
              fetchpriority="high"
              rel='payload'
              srcSet={`${Logo} 100w, ${Logo} 130w`} sizes="(max-width: 600px) 100px, 130px"
            />
          </Link>
          <div className={`${click ? "nav-options active" : "nav-options"} lg:flex xl:text-[18px] text-base font-bold xl:gap-7 lg:gap-5 gap-0`}>
            {/* <div onClick={() => setClick(false)}><NavHashLink to="/#solution" className='relative flex'>Solution</NavHashLink></div> */}
            <div className='relative'><Link href='#' className='relative flex' id="menu-about-button"
              aria-expanded={isSolutionMenuOpen}
              aria-haspopup="true"
              onMouseEnter={toggleSolutionMenu} onClick={toggleSolutionMenu}>Solution<RiArrowDropDownLine className='text-[26px]' /></Link>
              {isSolutionMenuOpen && (
                <div onClick={handleItemClick} onMouseLeave={handleBackgroundClick} className="absolute mobile_header_menu right-0 z-10 lg:mt-2 w-56 origin-top-right lg:rounded-md text-white bg-bgSecondary shadow-lg ring-1 ring-dark ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                  <div className="lg:py-1 m-auto" role="none" >
                    <Link to="/solutions/retail-video-analytics-system" className='block px-4 py-2 text-sm hover:bg-dark'>Retail</Link>
                    <Link to="/solutions/oil-gas-video-analytics" className='block px-4 py-2 text-sm hover:bg-dark'>Oil & Gas</Link>
                    <Link to="/solutions/video-analytics-construction" className='block px-4 py-2 text-sm hover:bg-dark'>Construction </Link>
                    <Link to="/solutions/smart-facial-recognition-solution" className='block px-4 py-2 text-sm hover:bg-dark'>Facial Recognition</Link>
                    <Link to="/solutions/smart-city-video-analytics" className='block px-4 py-2 text-sm hover:bg-dark'>Smart Cities</Link>
                    <Link to="/solutions/smart-manufacturing-analytics" className='block px-4 py-2 text-sm hover:bg-dark'>Manufacturing </Link>
                  </div>
                </div>
              )}
            </div>
            <div className='relative'><Link href='#' className='relative flex' id="menu-about-button"
              aria-expanded={isResourceMenuOpen}
              aria-haspopup="true"
              onMouseEnter={toggleResourceMenu} onClick={toggleResourceMenu}>Resources<RiArrowDropDownLine className='text-[26px]' /></Link>
              {isResourceMenuOpen && (
                <div onClick={handleItemClick} onMouseLeave={handleBackgroundClick} className="absolute mobile_header_menu right-0 z-10 lg:mt-2 w-56 origin-top-right lg:rounded-md text-white bg-bgSecondary shadow-lg ring-1 ring-dark ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                  <div className="lg:py-1 m-auto" role="none" >
                    <Link to="/blog" className='block px-4 py-2 text-sm hover:bg-dark'>Blogs</Link>
                  </div>
                </div>
              )}
            </div>
            <div onClick={() => setClick(false)}><Link to="/partner" className='relative flex'>Partners</Link></div>
            <div onClick={() => setClick(false)}><Link to="/contact-us" className='relative flex'>Contact us</Link></div>
          </div>
          <div className="mobile_header_menu lg:hidden block" onClick={handleClick}>
            {click ? (
              <IoClose className='text-3xl bg-transparent' onClick={handleClick} />
            ) : (
              <IoMenu className='text-3xl bg-transparent' onClick={handleClick} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header