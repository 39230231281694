import React, { Suspense } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Loader from '../../common/Loader';
const Aboutus = React.lazy(() => import('./Aboutus'));
const WhyPartner = React.lazy(() => import('./WhyPartner'));
const CTA = React.lazy(() => import('./CTA'));
const SuccessStories = React.lazy(() => import('./SuccessStories'));
const PartnerForm = React.lazy(() => import('./PartnerForm'));
const Overview = React.lazy(() => import('./Overview'));

const BecomePartner = () => {
  const title = 'Become a Partner - AI-Driven Video Analytics Software Solutions - VISNX';
  const description = 'Become a Partner - AI-Driven Video Analytics Software Solutions - VISNX';
  return (
    <HelmetProvider>
      <div className='bg-dark'>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{title}</title>
          <meta name="description" content={description} />
        </Helmet>
        <div className='relative'>
          <div className='partner_banner'></div>
          <div className='absolute top-0 right-0 w-full sm:h-screen h-[50vh] z-40'>
            <div className='flex flex-col md:gap-3 gap-3 justify-center items-end 2xl:max-w-[1500px] max-w-[95%] m-auto sm:pr-[5%] h-full w-full text-center'>
              <div className='flex justify-end flex-col items-end'>
                <div className='flex items-center gap-4 mb-3'>
                  <h1 className='md:text-7xl sm:text-5xl text-3xl font-medium'>Partner</h1>
                </div>
                <div className='md:text-xl sm:text-lg text-base font-normal sm:max-w-[500px] text-end'>
                  We have excellent AI Driven Detection Products.<br /> Let's Conquer the Market Together.
                </div>
              </div>
            </div>
          </div>
        </div>
        <Suspense fallback={<Loader />}>
          <Aboutus />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <WhyPartner />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <Overview />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <PartnerForm />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <SuccessStories />
        </Suspense>
        <Suspense fallback={<Loader />}>
          <CTA />
        </Suspense>
      </div>
    </HelmetProvider>
  )
}

export default BecomePartner