import React from 'react'

const Input = ({ type, placeholder, name, value, onChange, onBlur, onFocus,disabled,onKeyPress,max,required }) => {
    return (
        <>
            <input
                className="md:text-base text-sm block w-full appearance-none text-white border md:py-2 md:px-3 py-[6px] px-2 leading-tight focus:outline-none bg-transparent focus:bg-transparent"
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
                onKeyPress={onKeyPress}
                maxLength={max}
                required={required} />
        </>
    )
}

export default Input