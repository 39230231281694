import React from 'react'

const Button = ({ buttonName, onclick, type,className }) => {
    return (
        <>
            <button onClick={onclick} type={type} className={`${className ? className : ""} bg-transparent hover:bg-white  md:text-base text-sm hover:bg-gray-100 text-white hover:text-bgSecondary font-normal md:py-[5px] md:px-4 py-[3px] px-3 border border-white shadow`}>
                {buttonName}
            </button>
        </>
    )
}

export default Button