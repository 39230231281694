import React, { Suspense } from 'react'
import { Banner, Introduction, Help, Faq, FeaturesBenefits, Pricing } from '../components'
import { menuJohn, retailBenefits, menuProcessMonitoring, menuQualityControl, menuSafetyCompliance, retailsIntegration, retailsIntroduction, retailshelp } from '../../../helper/utils'
import Button from '../../../common/Button'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../common/Loader'
import CashStudies from '../components/CashStudies'



const featuresBenefits = [
  {
    img: menuProcessMonitoring,
    head: 'Process Monitoring',
    para: 'Automated monitoring of production lines for efficiency.',
  },
  {
    img: menuSafetyCompliance,
    head: 'Safety Compliance',
    para: 'Ensure workplace safety and regulatory compliance.',
  },
  {
    img: menuQualityControl,
    head: 'Quality Control',
    para: 'Advanced analytics for defect detection and quality assurance.',
  },
  {
    img: retailBenefits,
    head: 'Benefits',
    para: 'Increased production efficiency, enhanced worker safety, and superior product quality.',
  },
]

const pricingData = [
  {
    head: 'Starter',
    para: 'Ideal for small retail stores.',
    price: '$99.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Advanced',
    para: 'Designed for medium-sized businesses with more complex needs.',
    price: '$149.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Premium',
    para: 'Tailored for large retail chains and malls with custom feature requirements.',
    price: '$199.99',
    buttonName: 'Choose Plan'
  }
]

const cashStudies = [
  {
    review: "VISNX transformed our manufacturing operations, bringing unparalleled efficiency and safety improvements.",
    image: menuJohn,
    name: 'Lucas Tremblay',
    position: 'Operations Manager, MegaForge Manufacturing.'
  }
]

const faqList = [
  {
    que: 'How does VISNX ensure data privacy and security?',
    ans: 'We adhere to the highest standards of data protection and security protocols.',
  },
  {
    que: "Can VISNX integrate with older manufacturing equipment?",
    ans: 'Yes, VISNX is designed to be compatible with a wide range of manufacturing technologies, old and new.',
  }
]

const Manufacturing = () => {
  const navigate = useNavigate();
  return (
    <div className='bg-dark'>
      <Banner
        title='Revolutionize Manufacturing with Video Analytics | VISNX'
        description="VISNX leads the way in manufacturing innovation. Video Analytics ensures efficiency, safety, and quality control, streamlining processes for peak performance."
        heading='Revolutionize Manufacturing with VISNX Video Analytics'
        subHeading='Enhancing Efficiency, Safety, and Quality Control in Manufacturing'
        bannerImage='solution_manufacturing_banner'
      />
      <Introduction
        heading='Introduction to Video Analytics'
        para="In the fast-paced world of manufacturing, Video Analytics is a game-changer. VISNX leverages the power of AI to transform video data into actionable insights, optimizing production lines, enhancing safety, and ensuring quality. Embrace a new era of manufacturing excellence with VISNX."
        image={retailsIntroduction}
      />
      <Suspense fallback={<Loader />}>
        <Help
          heading='How We Help the Manufacturing Industry'
          para="VISNX is engineered to meet the diverse needs of the manufacturing sector. From monitoring equipment and production processes to ensuring worker safety and compliance, our solution delivers real-time insights for operational excellence. Streamline your manufacturing processes, reduce downtime, and maintain high-quality standards with VISNX."
          image={retailshelp}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <FeaturesBenefits featuresBenefits={featuresBenefits} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <CashStudies cashStudies={cashStudies} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Introduction
          heading='Integration and Compatibility'
          para="Seamlessly integrate VISNX with your existing manufacturing systems and camera setups. Our solution is designed for compatibility with a variety of industrial environments and can adapt to the specific needs of your manufacturing processes."
          image={retailsIntegration}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Pricing pricingData={pricingData} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Faq faqList={faqList} />
      </Suspense>
      <div className='relative'>
        <div className='contact_banner_home'></div>
        <div className='absolute bottom-0 left-0 w-full h-[200px] z-40 md:p-10 sm:p-7 p-4'>
          <div className='md:text-2xl sm:text-xl text-sm font-medium flex flex-col md:gap-3 gap-3 justify-center items-center h-full w-full text-center max-w-[1000px] m-auto'>
            <div className='text-center'>
              <div>Ready to enhance your manufacturing operations with VISNX? Contact us today to learn more or schedule a personalized demonstration. Take the first step towards smarter manufacturing.</div>
            </div>
            <div className=''><Button buttonName='Book a Demo' onclick={() => navigate('/contact-us')} /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Manufacturing