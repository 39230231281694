import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

import Button from '../../../common/Button'
import { useNavigate } from 'react-router-dom'

const Banner = ({ title,heading, subHeading,bannerImage,description }) => {
    const navigate = useNavigate();
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            <div className='relative'>
                <div className={bannerImage}></div>
                <div className='absolute top-0 right-0 w-full sm:h-[60vh] h-[50vh] z-40'>
                    <div className='flex flex-col md:gap-3 gap-3 justify-end items-start 2xl:max-w-[1500px] max-w-[95%] m-auto h-full w-full text-center pb-[2.5%]'>
                        <div className='flex justify-end flex-col items-start'>
                            <div className='flex items-center gap-4 md:mb-3 sm:mb-2 mb-1'>
                                <h1 className='md:text-4xl sm:text-2xl text-xl font-medium text-start max-w-[535px]'>{heading}</h1>
                            </div>
                            <div className='md:text-xl sm:text-lg text-base font-normal text-start'>
                                {subHeading}
                            </div>
                            <div className='md:pt-5 sm:pt-4 pt-3'>
                                <Button buttonName={'Request a Free Demo'} onclick={() => navigate('/contact-us')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    )
}

export default Banner