import React from 'react'
import { useNavigate } from 'react-router-dom'
import { blogData } from '../../helper'
import { Helmet, HelmetProvider } from 'react-helmet-async'


const Blog = () => {
  const navigate = useNavigate()
  const title = 'Blog - AI-Driven Video Analytics Software Solutions - VISNX';
  const description = 'Stay informed on cutting-edge AI in video analytics on the VISNX blog. Explore software solutions redefining safety, efficiency & quality control in various sectors.';

  const blogArray = blogData.slice().reverse();
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className='relative'>
        <div className='blog_banner'></div>
        <div className='absolute top-0 left-0 w-full h-[50vh] z-40'>
          <div className='md:text-4xl sm:text-2xl text-xl font-medium flex flex-col md:gap-3 gap-3 justify-center items-center 2xl:max-w-[1500px] max-w-[95%] m-auto h-full w-full text-center'>
            <div className='text-start'>
              <h1>Blogs</h1>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-dark'>
        <div className='2xl:max-w-[1500px] max-w-[95%] m-auto md:py-14 py-10 '>
          <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 sm:justify-center items-center gap-2'>
            {
              blogArray.map((data, i) => {
                return (
                  <div key={i} className='cursor-pointer p-4 hover:bg-bgSecondary transition-all duration-600 ease-out'
                    onClick={() => {
                      navigate(`/blog/${data.route}`)
                    }}
                  >
                    <img className='' src={data.image} alt='blog_banner' rel='payload' />
                    <div className='py-3 px-1'>
                      <div className='text-2xl font-normal mb-3 line-clamp-2 overflow-hidden'>{data.heading}</div>
                      <div className='text-base font-normal mb-3 line-clamp-3 overflow-hidden'>{data.para}</div>
                      <div className='border-b-[1px] border-[#ffffff43]'></div>
                      <div className='flex gap-2 items-center pt-2'>
                        <div className='text-xs'>{data.date}</div>
                        <div className='w-2 h-2 rounded-full bg-primary'></div>
                        <div className='text-xs'>Blog</div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </HelmetProvider>
  )
}

export default Blog