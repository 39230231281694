import React, { Suspense } from 'react'
import { Banner, Introduction, Help, Faq, FeaturesBenefits, Pricing } from '../components'
import { constructionJohn, retailBenefits, constructionProjectManagement, constructionResource, constructionSafety, retailsIntegration, retailsIntroduction, retailshelp } from '../../../helper/utils'
import Button from '../../../common/Button'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../common/Loader'
import CashStudies from '../components/CashStudies'



const featuresBenefits = [
  {
    img: constructionSafety,
    head: 'Safety Monitoring',
    para: ' Automated detection of safety hazards and compliance issues.',
  },
  {
    img: constructionResource,
    head: 'Resource Optimization',
    para: 'Insights into equipment and workforce utilization.',
  },
  {
    img: constructionProjectManagement,
    head: 'Project Management Support',
    para: 'Data-driven insights for efficient project management.',
  },
  {
    img: retailBenefits,
    head: 'Benefits',
    para: 'Improved site safety, optimized resource use, and enhanced project efficiency.',
  },
]

const pricingData = [
  {
    head: 'Starter',
    para: 'Ideal for small retail stores.',
    price: '$99.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Advanced',
    para: 'Designed for medium-sized businesses with more complex needs.',
    price: '$149.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Premium',
    para: 'Tailored for large retail chains and malls with custom feature requirements.',
    price: '$199.99',
    buttonName: 'Choose Plan'
  }
]

const cashStudies = [
  {
    review: "With VISNX, we've seen remarkable improvements in safety and efficiency. It's an indispensable tool for modern construction management.",
    image: constructionJohn,
    name: 'Oliver Wilson',
    position: 'Project Manager, BuildSphere Constructions'
  }
]

const faqList = [
  {
    que: 'How does VISNX contribute to construction site safety?',
    ans: 'VISNX uses AI to identify potential safety hazards in real-time, aiding in preventive measures.',
  },
  {
    que: 'Can VISNX be integrated with other project management tools?',
    ans: 'Absolutely, VISNX is designed to work seamlessly with various project management platforms.',
  }
]

const Construction = () => {
  const navigate = useNavigate();
  return (
    <div className='bg-dark'>
      <Banner
        title='Advanced Video Analytics for Construction Industry | VISNX'
        description="Unlock the potential of construction projects with VISNX. Advanced Video Analytics ensures safety, efficiency, and informed decision-making for successful outcomes."
        heading='Elevating Construction Sites with Advanced Video Analytics'
        subHeading='Maximize Safety and Efficiency in Your Construction Projects with VISNX'
        bannerImage='solution_construction_banner'
      />
      <Introduction
        heading='Introduction to Video Analytics'
        para='Video Analytics is revolutionizing construction site management. VISNX brings the power of AI-driven analytics to construction, providing critical insights for safety, efficiency, and compliance. With VISNX, transform raw video data into actionable intelligence, paving the way for smarter, safer construction sites.'
        image={retailsIntroduction}
      />
      <Suspense fallback={<Loader />}>
        <Help
          heading=' How We Help the Construction Industry'
          para="VISNX addresses the unique challenges of construction sites. From monitoring site safety to optimizing resource allocation, our solution delivers real-time insights for effective decision-making. Enhance site safety, reduce operational costs, and ensure timely project completion with VISNX's targeted analytics."
          image={retailshelp}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <FeaturesBenefits featuresBenefits={featuresBenefits} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <CashStudies cashStudies={cashStudies} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Introduction
          heading='Integration and Compatibility'
          para="Seamlessly integrate VISNX with your existing construction management systems and camera setups. Designed for versatility and scalability, VISNX adapts to the varied needs of construction projects, both large and small."
          image={retailsIntegration}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Pricing pricingData={pricingData} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Faq faqList={faqList} />
      </Suspense>
      <div className='relative'>
        <div className='contact_banner_home'></div>
        <div className='absolute bottom-0 left-0 w-full h-[200px] z-40 md:p-10 sm:p-7 p-4'>
          <div className='md:text-2xl sm:text-xl text-sm font-medium flex flex-col md:gap-3 gap-3 justify-center items-center h-full w-full text-center max-w-[1000px] m-auto'>
            <div className='text-center'>
              <div>Transform your construction site management with VISNX. Contact us today for a detailed demonstration or to discuss how we can tailor VISNX to your project's needs. Build smarter and safer with VISNX!</div>
            </div>
            <div className=''><Button buttonName='Book a Demo' onclick={() => navigate('/contact-us')} /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Construction