import React, { useEffect, useRef, useState } from 'react';
import Button from '../../common/Button';
import { useNavigate } from 'react-router-dom';
import { BannerImage } from '../../helper/utils';

const Banner = () => {
    const navigate = useNavigate();
    const videoRef = useRef(null);
    const imageRef = useRef(null);
    const [videoLoaded, setVideoLoaded] = useState(false);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.addEventListener('loadeddata', handleVideoLoaded);
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener('loadeddata', handleVideoLoaded);
            }
        };
    }, []);

    const handleVideoLoaded = () => {
        setVideoLoaded(true);
        videoRef.current.play().catch(error => {
            // Handle autoplay error here or provide a manual play button
        });
    };

    return (
        <div className='relative'>
            <div id="videoWrapper" className="relative w-full h-full">
                <img
                    ref={imageRef}
                    src={BannerImage}
                    alt="BannerImage"
                    className={`banner_image ${videoLoaded ? 'hidden' : ''}`}
                    width='1327'
                    height='737'
                    rel='payload'
                />
                <video
                    ref={videoRef}
                    loop
                    muted
                    preload='auto'
                    rel='payload'
                    autoPlay
                    className={`z-[50] h-full w-full object-cover ${!videoLoaded ? 'hidden' : ''}`}
                >
                    <source rel='payload' src={'https://visnx.s3.eu-north-1.amazonaws.com/Banner.mp4'} type="video/mp4" />
                </video>
            </div>
            <div className='absolute top-0 left-0 w-full h-full background_gradient z-40'>
                <div className='md:text-4xl sm:text-2xl text-base font-medium flex flex-col md:gap-3 gap-3 justify-end items-start 2xl:max-w-[1500px] max-w-[95%] m-auto pb-[5%] h-full w-full text-center'>
                    <div className='text-start'>
                        <h1>AI-Driven Video Analytics </h1>
                        <div>Transforming Businesses with AI</div>
                    </div>
                    <div className=''>
                        <Button buttonName='Book a Free Demo' onClick={() => navigate('/contact-us')} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
