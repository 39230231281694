import React, { useState } from 'react'
import Input from '../../common/Input'
import Button from '../../common/Button'
import { FiMapPin, BsPhone, HiOutlineMail } from '../../helper/icon'
import axios from 'axios'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const apiUrl = 'https://contactapi.mobiginie.com/api/v1/addContactus';

const Contact = () => {

  const initialValues = {
    name: "",
    email: "",
    phoneNo: "",
    message: "",
    domain: "https://visnx.com/"
  }
  const [contactForm, setContactForm] = useState(initialValues)
  const [sendMail, setSendMail] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target
    setContactForm({ ...contactForm, [name]: value });
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const payload = {
      ...contactForm
    }

    axios.post(apiUrl, payload, {
      headers: customHeaders,
    })
      .then(response => {
        // Handle success
        setContactForm(initialValues);
        setSendMail(true)
      })
      .catch(error => {
        // Handle error
        console.error('Error:', error);
      });
  }
  const customHeaders = {
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, DELETE, PATCH, POST, PUT',
    'Access-Control-Allow-Headers': 'X-CSRF-Token, X-Requested-With, Accept, Accept-Version, Content-Length, Content-MD5, Content-Type, Date, X-Api-Version'
  };
  const title = 'Contact Us - AI-Driven Video Analytics Software Solutions - VISNX';
  const description = 'Contact VISNX for Innovation and Excellence. Reach out for inquiries, support, or collaboration opportunities. Our dedicated team is here to assist you.';
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      <div className='relative'>
        <div className='contact_banner'></div>
        <div className='absolute top-0 left-0 w-full h-[50vh] z-40'>
          <div className='md:text-4xl sm:text-2xl text-xl font-medium flex flex-col md:gap-3 gap-3 justify-center items-center 2xl:max-w-[1500px] max-w-[95%] m-auto h-full w-full text-center'>
            <div className='text-start'>
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-dark'>
        <div className='2xl:max-w-[1500px] max-w-[95%] m-auto md:py-14 py-10 '>
          <div className='text-center mb-10'>
            <div className='text-3xl font-semibold'>Get in Touch</div>
            <div className='text-xl font-medium pt-2'>Book a Free Demo Now</div>
          </div>
          <div className='grid sm:grid-cols-2 sm:justify-center items-center gap-8'>
            <div className='flex flex-col sm:gap-10 gap-8 bg-bgSecondary h-full md:p-10 p-5 justify-center'>
              <div className='flex items-center gap-5'>
                <div className='border p-2'><FiMapPin className='text-xl ' /></div>
                <div>
                  <div className='font-bold'>Address</div>
                  <div className='text-sm font-light'>Fortune Hub, Science City Road, Ahmedabad, India</div>
                </div>
              </div>
              <div className='flex items-center gap-5'>
                <div className='border p-2'><BsPhone className='text-xl ' /></div>
                <div>
                  <div className='font-bold'>Phone</div>
                  <div className='text-sm font-light'><a href='tel: +91 95106 90757'>+91 95106 90757</a></div>
                </div>
              </div>
              <div className='flex items-center gap-5'>
                <div className='border p-2'><HiOutlineMail className='text-xl ' /></div>
                <div>
                  <div className='font-bold'>Email</div>
                  <div className='text-sm font-light'><a href="mailto: info@visnx.com">info@visnx.com</a></div>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className='flex flex-col gap-4'>
                <div>
                  <Input placeholder='Name' type='text' name='name' value={contactForm.name} onChange={handleChange} required/>
                </div>
                <div>
                  <Input placeholder='Email' type='email' name='email' value={contactForm.email} onChange={handleChange} required/>
                </div>
                <div>
                  <Input placeholder='Mobile Number' type='text' name='phoneNo' value={contactForm.phoneNo} onChange={handleChange}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }} max='10' required/>
                </div>
                <div className='h-full'>
                  <textarea rows='4' name='message' value={contactForm.message} onChange={handleChange} required
                    className="md:text-base text-sm h-full appearance-none block w-full text-white border py-2 px-3 leading-tight focus:outline-none bg-transparent focus:bg-transparent" placeholder='Message'></textarea>
                </div>
                <div className=''>
                  <Button buttonName='Book a Free Demo' type='submit' />
                </div>
              </div>
            </form>
            {
              sendMail &&
              <div className='mt-5 border-2 border-green-500 p-3'>
                Thank you! We have Received your message and someone from our team will contact you within 24 Hours. If you want to call us right away, Call us on <a href='tel:+919510690757'> +919510690757 </a>
              </div>
            }
          </div>
        </div>
      </div>
    </HelmetProvider>

  )
}

export default Contact