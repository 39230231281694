import React, { Suspense } from 'react'
import { Banner, Introduction, Help, Faq, FeaturesBenefits, Pricing } from '../components'
import { retailJohn, retailsIntegration, retailsIntroduction, retailshelp, retailBehavior, retailBenefits, retailInventory, retailTheft, } from '../../../helper/utils'
import Button from '../../../common/Button'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../common/Loader'
import CashStudies from '../components/CashStudies'

const faqList = [
  {
    que: 'How easy is it to integrate VISNX with my current system?',
    ans: 'VISNX is designed for easy integration with most existing retail systems and cameras.',
  },
  {
    que: 'Can VISNX help in reducing shoplifting and fraud?',
    ans: 'Yes, VISNX offers advanced security features to detect and alert on potential thefts and frauds.',
  }
]

const featuresBenefits = [
  {
    img: retailBehavior,
    head: 'Customer Behavior Analysis',
    para: 'Gain insights into customer preferences and behaviors.',
  },
  {
    img: retailInventory,
    head: 'Inventory Management',
    para: 'Automated monitoring to optimize stock levels.',
  },
  {
    img: retailTheft,
    head: 'Theft Prevention',
    para: 'Enhanced security with real-time theft and fraud detection.',
  },
  {
    img: retailBenefits,
    head: 'Benefits',
    para: 'These features lead to improved customer engagement, efficient inventory management, and enhanced store security.',
  },
]

const pricingData = [
  {
    head: 'Starter',
    para: 'Ideal for small retail stores.',
    price: '$99.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Advanced',
    para: 'Designed for medium-sized businesses with more complex needs.',
    price: '$149.99',
    buttonName: 'Choose Plan'
  },
  {
    head: 'Premium',
    para: 'Tailored for large retail chains and malls with custom feature requirements.',
    price: '$199.99',
    buttonName: 'Choose Plan'
  }
]

const cashStudies = [
  {
    review: 'VISNX has been instrumental in optimizing our store operations and enhancing customer satisfaction.',
    image: retailJohn,
    name: 'David Caldwell',
    position: 'Operations Manager, VistaVend'
  }
]

const Retails = () => {
  const navigate = useNavigate();
  return (
    <div className='bg-dark'>
      <Banner
        title='Power of Smart Video Analytics in Retail Industry | VISNX'
        description="Revolutionize retail efficiency with VISNX's Smart Video Analytics. Elevate your operations, unlock full potential. Transforming retail with cutting-edge technology."
        heading='Transforming Retail with Smart Video Analytics'
        subHeading='Unlock the Full Potential of Your Retail Operations with VISNX'
        bannerImage='solution_retail_banner'
      />
      <Introduction
        heading='Introduction to Video Analytics'
        para='In today’s digital era, Video Analytics is a game-changer for retail businesses. VISNX leverages advanced AI to provide actionable insights from video data, enhancing customer experience, optimizing store operations, and boosting sales. Embrace the future of retail with the intelligence of VISNX.'
        image={retailsIntroduction}
      />
      <Suspense fallback={<Loader />}>
        <Help
          heading='How We Help the Retail Industry'
          para='VISNX caters to the dynamic needs of the retail industry. From tracking customer footfall and behavior to optimizing store layouts and improving security, our solution transforms raw video into valuable insights. Enhance customer satisfaction, streamline operations, and increase profitability with VISNX’s tailored analytics.'
          image={retailshelp}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <FeaturesBenefits featuresBenefits={featuresBenefits} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <CashStudies cashStudies={cashStudies} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Introduction
          heading='Integration and Compatibility'
          para='VISNX integrates smoothly with your existing camera systems and retail management software, ensuring a seamless adoption of advanced analytics. Its flexible and scalable design is perfect for retail businesses of all sizes.'
          image={retailsIntegration}
        />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Pricing pricingData={pricingData} />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <Faq faqList={faqList} />
      </Suspense>
      <div className='relative'>
        <div className='contact_banner_home'></div>
        <div className='absolute bottom-0 left-0 w-full h-[200px] z-40 md:p-10 sm:p-7 p-4'>
          <div className='md:text-2xl sm:text-xl text-sm font-medium flex flex-col md:gap-3 gap-3 justify-center items-center h-full w-full text-center max-w-[1000px] m-auto'>
            <div className='text-center'>
              <div>Ready to elevate your retail business with the power of VISNX? Contact us to schedule a demo or discuss your unique needs. Transform your retail operations today!</div>
            </div>
            <div className=''><Button buttonName='Book a Demo' onclick={() => navigate('/contact-us')} /></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Retails