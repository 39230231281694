import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigatePage();
  }, )

  const navigatePage = () => {
    navigate("/error404")
  }
  return (
    <div className='h-screen bg-dark'>
      <div className='h-full flex items-center justify-center gap-4'>
        <div className='text-3xl'>404</div>
        <div className='border-r border-white h-14'></div>
        <div className='text-lg'>This page could not be found.</div>
      </div>
    </div>
  )
}

export default PageNotFound 